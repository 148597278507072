import React, { useState, CSSProperties } from 'react';

import {
    useCSVReader,
    lightenDarkenColor,
    formatFileSize,
} from 'react-papaparse';

const AWS = require('aws-sdk');

AWS.config.update({
    region: 'us-east-1',
    credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: "us-east-1:26b8cf00-d20e-4adf-9291-340d729ced50"
    })
});

var s3 = new AWS.S3({
    params: { Bucket: 'datacompare' }
});

const GREY = '#CCC';
const GREY_LIGHT = 'rgba(255, 255, 255, 0.4)';
const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
    DEFAULT_REMOVE_HOVER_COLOR,
    40
);
const GREY_DIM = '#686868';

const styles = {
    zone: {
        alignItems: 'center',
        border: `2px dashed #ffffff4a`,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        padding: 20,
    },
    file: {
        borderRadius: 2,
        display: 'flex',
        height: 80,
        width: 160,
        position: 'relative',
        zIndex: 10,
        flexDirection: 'column',
        justifyContent: 'center',
        // border:'1px solid white'
    } ,
    info: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 10,
        paddingRight: 10,
    } ,
    size: {
        borderRadius: 3,
        marginBottom: '0.5em',
        justifyContent: 'center',
        display: 'flex',
    } ,
    name: {
        borderRadius: 3,
        fontSize: 12,
        marginBottom: '0.5em',
    } ,
    progressBar: {
        bottom: 14,
        position: 'absolute',
        width: '100%',
        paddingLeft: 10,
        paddingRight: 10,
    } ,
    zoneHover: {
        borderColor: GREY_DIM,
    } ,
    default: {
        borderColor: GREY,
    } ,
    remove: {
        height: 23,
        position: 'absolute',
        right: 6,
        top: 6,
        width: 23,
    } ,
};

export default function CSVReader({resultsCallback}) {
    const { CSVReader } = useCSVReader();
    const [zoneHover, setZoneHover] = useState(false);
    const [removeHoverColor, setRemoveHoverColor] = useState(
        DEFAULT_REMOVE_HOVER_COLOR
    );

    return (
        <CSVReader
            onUploadAccepted={(results, file) => {
                console.log('---------------------------');
                console.log(results, file);
                console.log('---------------------------');
                debugger
                resultsCallback(results, file)


                //
                // var upload = new AWS.S3.ManagedUpload({
                //     params: {
                //         Bucket: 'datacompare',
                //         Key: file.name,
                //         Body: file
                //     }
                // });
                //
                // var promise = upload.promise();
                //
                // promise.then(
                //     function(data) {
                //         alert("Successfully uploaded photo.");
                //     },
                //     function(err) {
                //         return alert("There was an error uploading your photo: ", err.message);
                //     }
                // );




                setZoneHover(false);
            }}
            onDragOver={(event) => {
                event.preventDefault();
                setZoneHover(true);
            }}
            onDragLeave={(event) => {
                event.preventDefault();
                setZoneHover(false);
            }}
        >
            {({
                  getRootProps,
                  acceptedFile,
                  ProgressBar,
                  getRemoveFileProps,
                  Remove,
              }) => (
                <>
                    <div
                        className={"uploadBox"}
                        {...getRootProps()}
                        style={{...Object.assign(
                            {},
                            styles.zone,
                            zoneHover && styles.zoneHover
                        ), color:'white'}}
                    >
                        {acceptedFile ? (
                            <>
                                <div style={styles.file}>
                    {/*                <div style={styles.info}>*/}
                    {/*<span style={styles.size}>*/}
                    {/*  {formatFileSize(acceptedFile.size)}*/}
                    {/*</span>*/}
                    {/*                    <span style={styles.name}>{acceptedFile.name}</span>*/}
                    {/*                </div>*/}
                                    <div style={styles.progressBar}>
                                        Click to upload another
                                        <ProgressBar />
                                    </div>
                                    {/*<div*/}
                                    {/*    {...getRemoveFileProps()}*/}
                                    {/*    style={styles.remove}*/}
                                    {/*    onMouseOver={(event) => {*/}
                                    {/*        event.preventDefault();*/}
                                    {/*        setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);*/}
                                    {/*    }}*/}
                                    {/*    onMouseOut={(event) => {*/}
                                    {/*        event.preventDefault();*/}
                                    {/*        setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    {/*    <Remove color={removeHoverColor} />*/}
                                    {/*</div>*/}
                                </div>
                            </>
                        ) : (
                            'Click to upload'
                        )}
                    </div>
                </>
            )}
        </CSVReader>
    );
}