import './App.css';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import {useState, useEffect} from "react";
import CSVReader from "./CSVUpload";
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsDraggablePoints from 'highcharts/modules/draggable-points'

import {Button, Slider, Switch, Input, Collapse} from "antd";
import {produce} from "immer";
import {darktheme} from "./darktheme";
import useWindowSize from "./useWindowSize";
import {SettingOutlined} from '@ant-design/icons';
import {usePapaParse} from "react-papaparse";
import {getAccessTokenFromUrl, getDbxAuthLink} from "./dropboxAuth";
import {getWithExpiry} from "./localStorageSend";

export const Dropbox = require("dropbox");

const { Panel } = Collapse;

HighchartsExporting(Highcharts);


async function downloadFile(link, resultsCallback) {
    var ACCESS_TOKEN = process.env.REACT_APP_DROPBOX_API_KEY;
    var SHARED_LINK = link;
    var dbx = new Dropbox.Dropbox({accessToken: ACCESS_TOKEN});
    // console.log(await dbx.filesListFolder({path:""}))

    dbx.sharingGetSharedLinkFile({url: SHARED_LINK})
        .then(function (data) {
            debugger;
            console.log(data?.result?.fileBlob);
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = URL.createObjectURL(data?.result?.fileBlob);
            a.download = data?.result?.name;
            a.click();
            resultsCallback(URL.createObjectURL(data?.result?.fileBlob))
        })
        .catch(function (error) {
            console.error(error);
        });
    return false;
}


const SeriesSelectionPane = ({setState, ignoreFirst, setNewState, newState, setNewSets, setSets, sets, newSets, controls, createSet}) => {

    return <>
    <div style={{display: "flex", cursor: "pointer"}}>
        <div style={{width: '80%', margin: "auto", marginBottom: 40, marginTop: 40}}>
            <CSVReader resultsCallback={(result, file) => {
                setState(result);
                debugger;
                if(newState) setNewState([...newState, result])
                else setNewState([result])

                const moreSets = result?.data?.[ignoreFirst ? 1 : 0]?.map(setname => createSet(setname))
                setSets(moreSets)
                setNewSets(newSets ? [...newSets, {name: file?.name, sets:moreSets}] : [{name:file?.name, sets:moreSets}])
            }
            }/>
            {/*<FileUpload setState={setState} title={"Click to upload ASL data"}/>*/}
        </div>
    </div>
    <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(1, 1fr)'
    }}>
        <Collapse ghost>
        {newSets?.map((series, seriesIndex) => {
            const seriesSets = series?.sets
            return <Panel style={{color:'white', width:"100%", wordBreak:"break-all"}} header={series?.name}>
                {seriesSets?.map((set, ind) => {
                return <div className={!seriesSets[ind].active ? 'setLabel' : null}
                            onClick={() => {
                                if (!newSets[seriesIndex].sets[ind].active) {
                                    const updatedSets = produce(newSets, (draft) => {
                                        draft[seriesIndex].sets[ind].active = !draft[seriesIndex].sets[ind].active
                                    });
                                    setNewSets(updatedSets)
                                }
                            }}
                            style={{
                                background: 'transparent',
                                border: '1px solid #ffffff4a',
                                width: '80%',
                                margin: 'auto',
                                marginBottom: 20,
                                height: null,
                                display: 'flex',
                                justifyContent: 'center',
                                textAlign: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                color: 'white',
                                borderRadius: 5,
                                padding: 10
                            }}>
                    {set?.setName}
                    {set?.active && <>
                        {controls?.map(control => {
                                return <div style={{width: "80%"}}>
                                    {control?.label}
                                    { control?.control === 'slider' &&

                                        <Slider value={newSets?.[seriesIndex]?.sets?.[ind]?.[control?.property]}
                                            onChange={(e) => {
                                                const updatedSets = produce(newSets, (draft) => {
                                                    draft[seriesIndex].sets[ind][control.property] = e
                                                });
                                                setNewSets(updatedSets)
                                            }}
                                            {...control}/>}
                                    { control?.control === 'switch' &&

                                    <Switch checked={newSets?.[seriesIndex]?.sets?.[ind]?.[control?.property]}
                                            onChange={(e) => {
                                                const updatedSets = produce(newSets, (draft) => {
                                                    draft[seriesIndex].sets[ind][control.property] = !draft[seriesIndex].sets[ind][control.property]
                                                });
                                                setNewSets(updatedSets)
                                            }}
                                            {...control}/>}
                                </div>
                            }
                        )}

                        <div
                            onClick={() => {
                                if (newSets?.[seriesIndex]?.sets?.[ind]?.active) {
                                    const updatedSets = produce(newSets, (draft) => {
                                        draft[seriesIndex].sets[ind] = createSet(draft[seriesIndex].sets[ind].setName);
                                        draft[seriesIndex].sets[ind].active = true
                                    });
                                    setNewSets(updatedSets)
                                }
                            }}
                            className={'xout'}
                            style={{
                                width: '100%',
                                background: 'white',
                                color: '#0a1256',
                                padding: 5,
                                marginTop: 10,
                                borderRadius: 4
                            }}> reset
                        </div>
                        <div
                            onClick={() => {
                                if (newSets?.[seriesIndex]?.sets?.[ind]?.active) {
                                    const updatedSets = produce(newSets, (draft) => {
                                        draft[seriesIndex].sets[ind].active = !draft[seriesIndex].sets[ind].active
                                    });
                                    setNewSets(updatedSets)
                                }
                            }}
                            className={'xout'}
                            style={{
                                width: '100%',
                                background: 'white',
                                color: '#0a1256',
                                padding: 5,
                                marginTop: 10,
                                borderRadius: 4
                            }}> X
                        </div>
                    </>
                    }
                </div>
            })}</Panel>
        })}
        </Collapse>
    </div>
</>;}

const SharePane = ({setState, setNewState, newState, state, setSets, setNewSets, newSets, sets, controls, createSet}) => {

    const {readRemoteFile} = usePapaParse();
    const [link, setLink] = useState("");
    const resultsCallback = (result) => {
        debugger;
        const updatedState = state ? {
            ...state, data: state?.data?.reduce((c, p, i) => {
                if(result?.data?.[i]) p = [...p, ...result?.data?.[i]]
                c.push(p);
                return c
            }, [])
        } : result
        setState(updatedState);
        // setNewState(updatedState)
        setNewState(newState ? [...newState, result] : [result])


        setSets([...sets, ...result?.data?.[0]?.map(setname => createSet(setname))])

        const moreSets = result?.data?.[0]?.map(setname => createSet(setname))
        setSets(moreSets)

        setNewSets(newSets ? [...newSets, {sets:moreSets}] : [{sets:moreSets}])
    };
    const handleReadRemoteFile = (url) => {
        readRemoteFile(url, {
            complete: resultsCallback
        });
    };

    return <>
        <div style={{width: '80%', margin: "auto", marginBottom: 40, marginTop: 40}}>

            <Input value={link} onChange={(e) => setLink(e.target.value)}/>
            <Button onClick={() => downloadFile(link, handleReadRemoteFile)}> Import </Button>
        </div>
    </>;
};


const ChartSettings = ({chart, pointsEnabled,ignoreFirst, setIgnoreFirst, setPointsEnabled, sharedEnabled, setSharedEnabled, sets, setSets, state}) =>
    <div
        style={{}}>
        <div style={{border:"1px solid grey", borderRadius:8, width:'80%', margin:'auto', padding:20, marginBottom:20}}>Points Enabled:<Switch style={{marginLeft:30}} checked={pointsEnabled} onChange={
        () => {
            setPointsEnabled(!pointsEnabled);
            // chart.xAxis[0].setExtremes(1000,10000);
        }}/>
        </div>
        <div style={{border:"1px solid grey", borderRadius:8, width:'80%', margin:'auto', padding:20, marginBottom:20}}>Share Enabled:<Switch style={{marginLeft:30}}  checked={sharedEnabled} onChange={() => setSharedEnabled(!sharedEnabled)}/>
        </div>
        <div style={{border:"1px solid grey", borderRadius:8, width:'80%', margin:'auto', padding:20, marginBottom:20}}>Ignore First Line: (warning, applies to all)<Switch style={{marginLeft:30}}  checked={ignoreFirst} onChange={() => setIgnoreFirst(!ignoreFirst)}/>
        </div>
    </div>;


const Nav = ({tabClicked, setTabClicked, tabs, mobileStateOpen}) => <div
    style={{
        flex: 2,
        backgroundColor: '#0a1256',
        height: '100%',
        overflow: 'scroll',
        minWidth: 300,
        borderRight: '1px solid #525563'
    }}
    className={mobileStateOpen ? "navContainer" : "navContainerClosed"}
>
    <div style={{
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#16207b',
        width: '90%',
        margin: 'auto',
        marginTop: 10,
        marginBottom: 20,
        padding: 6,
        borderRadius: 8,
        position: "sticky",
        top: 10,
        zIndex: 1000
    }}>{tabs?.map((tab, ind) => {
        return <div className={"tabElement"} style={{
            flex: 1, padding: 4,
            cursor: "pointer",
            background: tabClicked === ind ? '#0a1256' : null,
            color: tabClicked === ind ? null : '#595C75',
            borderRadius: 6,
        }} onClick={() => setTabClicked(ind)}>{tab?.title}</div>
    })}
    </div>
    {tabs?.[tabClicked]?.content()}
</div>;


function App() {
    const [state, setState] = useState(null);
    const [newState, setNewState] = useState(null);
    const [sets, setSets] = useState([]);
    const [newSets, setNewSets] = useState([]);

    const [pointsEnabled, setPointsEnabled] = useState(false);
    const [sharedEnabled, setSharedEnabled] = useState(false);
    const [ignoreFirst, setIgnoreFirst] = useState(true);
    const [tabClicked, setTabClicked] = useState(0);
    const [chart, setChart] = useState(null);
    const [mobileStateOpen, setMobileStateOpen] = useState(false);
    const [dbxLogin, setDbxLogin] = useState("")

    const windowSize = useWindowSize();

    // const dropboxFlow = async () => {
    //     const dbxAuth = getWithExpiry('dbxAuthToken');
    //     const token = getAccessTokenFromUrl();
    //     getDbxAuthLink(setDbxLogin)
    //
    // }

    useEffect(() => {
    },[])


    const createSet = (setName) => ({
        setName,
        active: false,
        graphType: null,
        color: null,
        scaleY: 0,
        scaleX: 1,
        offsetY: 0,
        offsetX: 0,
        draggableX:true,
        normal:true,
        integral: false,
        derivative: false
    });

    const series = newSets?.map((set, setInd) => {
        return set?.sets?.reduce((result, element, i) => {
            if (element?.active) {
                const data = newState?.[setInd]?.data?.map(arr => Number(arr[i])).slice(ignoreFirst ? 2 : 1) ?? [];
                debugger;
                const xShifted = element?.offsetX >= 0 ? [...Array(element?.offsetX).fill(0), ...data] : data.slice(-element?.offsetX, data?.length)
                const yShifted = xShifted?.map(elem => elem + element?.offsetY);
                const yScaled = element?.scaleY === 0 ? yShifted :
                    element?.scaleY >= 0  ? yShifted?.map(elem => elem * element?.scaleY) :
                        yShifted?.map(elem => elem / -element?.scaleY)
                ;
                const integral = xShifted?.reduce((prev, current, i) => {
                    if(i === 0 ){
                        return [0]
                    }
                    prev.push((current/60)*1000*0.01 + prev[i-1]);
                    return prev
                }, [])

                const derivative = xShifted?.reduce((prev, current, i) => {
                    if(i === 0 ){
                        return [0]
                    }
                    prev.push((xShifted?.[i] - xShifted?.[i-1])*6)
                    return prev
                }, [])

                const downScaled = yShifted?.reduce((r, e, i) => {
                    if (!(i % element?.scaleX)) {
                        r.push([i, e])
                    }
                    return r
                }, []);
                if(element?.normal) result.push({
                    boostThreshold: 10000,
                    data: yScaled,
                    nonScaledData:xShifted,
                    name: element?.setName,
                    marker: {
                        enabled: pointsEnabled,
                        radius: 3,
                        enabledThreshold: 10
                    }
                })
                if(element?.integral) result.push({
                    boostThreshold: 10000,
                    data: integral,
                    nonScaledData:integral,
                    name: `${element?.setName} integral`,
                    marker: {
                        enabled: pointsEnabled,
                        radius: 3,
                        enabledThreshold: 10
                    }
                })
                if(element?.derivative) result.push({
                    boostThreshold: 10000,
                    data: derivative,
                    nonScaledData:derivative,
                    name: `${element?.setName} derivative `,
                    marker: {
                        enabled: pointsEnabled,
                        radius: 3,
                        enabledThreshold: 10
                    }
                })
            }
            return result
        }, [])
    },[])?.reduce((p, c, setInd) => {
        debugger
        return [...p, ...c]
    },[]);

    debugger;

    const options = {
        ...darktheme,
        rangeSelector: {
            inputEnabled: false,
            allButtonsEnabled: false,
            buttons: []
        },
        exporting: {
            enabled: true
        },
        plotOptions: {
            series: {
                marker: {
                    enabled: true
                },
                point:{
                    events:{
                        drag: (e) => console.log(e)
                    }
                },
                dragDrop: {
                    draggableX: true,
                    draggableY: true,
                    dragMinY: 0,
                    dragMaxY: 0,
                    // dragMinX: Date.UTC(2014, 10, 15),
                    // dragMaxX: Date.UTC(2015, 0, 10),
                    // liveRedraw: false,
                    // groupBy: 'groupId' // Group data points with the same groupId
                },
                ema: {point: {
                    events:{
                        drag: (e) => console.log(e)
                    }
                }
                }
            }
        },
        chart: {
            // type:'area',
            zoomType: 'x',
            backgroundColor: 'transparent',
            height: windowSize?.height * 0.9,
            events: {
                load: function () {
                    setChart(this)
                }
            }
        },
        title: {
            style: {
                color: "white"
            }

        },
        xAxis: {
            AxisTypeValue: 'linear',
            labels:
                {
                    formatter: function () {
                        return this.value;
                    }
                },
            events: {
                setExtremes: (e) => console.log(e)
            },
            extremes: {}
        },
        tooltip: {
            valueDecimals: 2,
            distance: 50,
            ...(sharedEnabled ? {
                split: true,
                share: false,
                formatter: function (tooltip) {
                    debugger;
                    return `${tooltip.defaultFormatter.call(this, tooltip)}\npoint: ${this.points[0].x} IGNORE THIS MODE, DATA INCORRECT`;
                }
            } : {
                formatter: function (tooltip) {
                    const current = tooltip.defaultFormatter.call(this, tooltip);
                    current[0] = `<span style="font-size: 10px">${this.points[0].x}</span>`;
                    // current[1] = 5;
                    current.forEach((s, ind) => {
                        if(!ind) return
                        debugger;
                        current[ind] = `${series?.[ind - 1]?.name}: ${series?.[ind - 1]?.nonScaledData?.[this.x]}`
                    })
                    return current
                }
            })

            // formatter: function() {
            //     const name = this.series.name
            //     const set = sets.filter((set) => set?.setName === name)?.[0] // TODO: fix
            //     return `${name}: ${(this.point.y - set?.offsetY) / set?.scaleY}`
            // }
        },
        series: series,
    };

    const controls = [
        {label: 'Scale Y', property: 'scaleY', control: 'slider', min: -40, max: 40},
        // {label: 'Downscale X', property: 'scaleX', control: 'slider', min: 0, max: 10, step: 1},
        {label: 'Offset X', property: 'offsetX', control: 'slider', min: -1000, max: 1000, step: 1},
        {label: 'Offset Y', property: 'offsetY', control: 'slider', min: -1000, max: 1000, step: 1},
        {label: 'normal series', property: 'normal', control: 'switch'},
        {label: 'Rough Integral', property: 'integral', control: 'switch'},
        {label: 'Rough Derivative', property: 'derivative', control: 'switch'},
    ];


    const tabs = [
        {title: 'Series', content: () => <SeriesSelectionPane {...{
            ignoreFirst, setState, setNewState, newState, setSets, sets, newSets, setNewSets, controls, createSet}}/>},
        {
            title: 'Chart',
            content: () => <ChartSettings {...{
                chart,
                pointsEnabled,
                setPointsEnabled,
                sharedEnabled,
                setSharedEnabled,
                createSet,
                sets,
                setSets,
                state,
                ignoreFirst,
                setIgnoreFirst
            }}/>
        },
        {
            title: 'Share',
            content: () => <SharePane {...{
                chart,
                pointsEnabled,
                setPointsEnabled,
                sharedEnabled,
                setSharedEnabled,
                createSet,
                sets,
                setSets,
                setState,
                setNewState,
                state,
                newSets,
                setNewSets,
                newState
            }}/>
        }
    ];


    return (
        <div className="App">
            <div
                onClick={() => setMobileStateOpen(!mobileStateOpen)}
                style={{fontSize: 30, top: 6, left: 30, cursor: "pointer", position: 'fixed', color: '#0a1256'}}
                className={"gear"}>
                {mobileStateOpen ? 'X' : <SettingOutlined style={{color: '#0a1256'}}/>}
            </div>
            <div style={{maxWidth: '100vw', height: '100vh', display: 'flex', background: 'white'}}>
                <Nav {...{tabClicked, setTabClicked, tabs, mobileStateOpen}}/>
                <div style={{flex: 8, justifyContent: 'center', background: "#0a1256", overflow: "auto"}}>
                    <div style={{height: '60px', background: "white", borderBottom: '1px solid white'}}>
                        <div style={{
                            backgroundSize: 'contain',
                            width: 175,
                            position: 'relative',
                            float: "right",
                            marginRight: 30,
                            height: "100%",
                            background: "center / contain no-repeat url('https://static.wixstatic.com/media/d69edc_5f7f70b0593a4b0da4562ea9ce9374d2~mv2.png/v1/crop/x_81,y_227,w_470,h_146/fill/w_356,h_116,al_c,usm_0.66_1.00_0.01,enc_auto/Ventis_WEB_%2520Logo_White_BG_H_tagline_TM.png')"
                        }}/>
                    </div>
                    <div style={{width: "100%", height: "100%", margin: "auto"}}>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            constructorType={'stockChart'}
                        />
                    </div>
                </div>
            </div>
            {/*{JSON.stringify(state)}*/}
        </div>
    );
}

export default App;
